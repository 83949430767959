<template>
  <div>
    <b-button v-b-modal.newitem class="mb-3">Nieuw artikel aanmaken</b-button>
    <b-form @submit="onSubmit">
      <b-modal id="newitem" title="Nieuw artikel toevoegen">
        <b-form-group id="itemidlabel" label="Artikelnummer" label-for="itemid">
          <b-form-input
            id="itemid"
            v-model="form.itemid"
            type="text"
            placeholder="Voer het artikelnummer in"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="itemdescriptionlabel"
          label="Omschrijving"
          label-for="itemdescription"
        >
          <b-form-input
            id="itemdescription"
            v-model="form.itemdescription"
            placeholder="Geef een omschrijving van het artikel op"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="commentlabel" label="Opmerking" label-for="comment">
          <b-form-textarea
            id="comment"
            v-model="form.comment"
            placeholder="Geef eventueel een opmerking op"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-checkbox
          id="public-serials"
          v-model="status"
          name="form.publicSerials"
          :value="true"
          :unchecked-value="false"
        >
          Serienummer gegevens kunnen zonder in te loggen doormiddel van een
          link of QR gedeeld worden voor inzage van keurings documenten en
          dergelijke.
        </b-form-checkbox>

        <b-card class="mt-3" header="Form Data Result">
          <pre class="m-0">{{ form }}</pre>
        </b-card>
        <template #modal-footer>
          <div class="w-100">
            <b-button type="submit" variant="primary"
              >Artikel aanmaken</b-button
            >
            <b-button
              @click="hideModal()"
              variant="secondary"
              class="float-right"
            >
              Sluiten
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BFormTextarea,
  BFormCheckbox
} from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BFormTextarea,
    BFormCheckbox
  },
  data() {
    return {
      form: {
        itemid: '',
        itemdescription: '',
        comment: '',
        publicSerials: false
      }
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide(`newitem`)
    },
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    }
  }
}
</script>

<template>
  <div>
    <h3>Mijn bedrijf</h3>
    <p v-if="isMainContact">
      Indien deze gegevens niet correct zijn, gelieve de hoofdverantwoordelijke
      te contacteren. Enkel de hoofdverantwoordelijke kan de gegevens van de
      bedrijf wijzigen.
    </p>
    <p v-if="!isMainContact">
      De hoofdverantwoordelijke is de enige die de gegevens van de bedrijf kan
      wijzigen. Contactpersonen kunnen de gegevens bekijken, niet wijzigen.
    </p>
    <b-form @submit.prevent="adjustCustomer">
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-name"
            label="Naam bedrijf"
            label-for="input-customer-name"
          >
            <b-form-input
              id="input-customer-name"
              v-model="form.customerName"
              type="text"
              placeholder="Naam bedrijf"
              :disabled="isMainContact"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-organisation-number"
            label="Ondernemeningsnummer"
            label-for="input-customer-organisation-number"
          >
            <b-form-input
              id="input-customer-organisation-number"
              v-model="form.customerOrganisationNumber"
              type="text"
              :disabled="isMainContact"
              placeholder="Ondernemingsnummer voor eventuele facturatie"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12">
          <b-form-group
            id="input-group-customer-description"
            label="Omschrijving van de bedrijf"
            label-for="input-customer-description"
          >
            <b-form-textarea
              id="input-customer-description"
              v-model="form.customerDescription"
              placeholder="Omschrijf kort waar de bedrijf voor staat"
              rows="3"
              :disabled="isMainContact"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-website"
            label="Website"
            label-for="input-customer-website"
          >
            <b-form-input
              id="input-customer-website"
              v-model="form.website"
              type="text"
              :disabled="isMainContact"
              placeholder="Website"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-CUST_SocialMedia"
            label="Socialmedia"
            label-for="input-customer-CUST_SocialMedia"
          >
            <b-form-input
              id="input-customer-CUST_SocialMedia"
              v-model="form.CUST_SocialMedia"
              type="text"
              :disabled="isMainContact"
              placeholder="Socialmedia"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12">
          <b-form-group
            id="input-group-customer-email"
            label="E-mailadres"
            label-for="input-customer-email"
          >
            <b-form-input
              id="input-customer-email"
              v-model="form.customerEmail"
              type="text"
              :disabled="isMainContact"
              placeholder="E-mailadres"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-street"
            label="Straat"
            label-for="input-street"
          >
            <b-form-input
              id="input-street"
              v-model="form.CustomerStreet"
              type="text"
              :disabled="isMainContact"
              placeholder="Straat"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group
            id="input-group-housenumber"
            label="Huisnummer"
            label-for="input-housenumber"
          >
            <b-form-input
              id="input-housenumber"
              v-model="form.housenumber"
              type="text"
              :disabled="isMainContact"
              placeholder="Huisnummer"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group
            id="input-group-housenumberaddition"
            label="Toevoeging"
            label-for="input-housenumberaddition"
          >
            <b-form-input
              id="input-housenumberaddition"
              v-model="form.housenumberaddition"
              type="text"
              :disabled="isMainContact"
              placeholder="Toevoeging"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-postal"
            label="Postcode"
            label-for="input-postal"
          >
            <b-form-input
              id="input-postal"
              v-model="form.zipCode"
              type="text"
              :disabled="isMainContact"
              placeholder="Postcode"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-city"
            label="Stad"
            label-for="input-city"
          >
            <b-form-input
              id="input-city"
              v-model="form.city"
              type="text"
              :disabled="isMainContact"
              placeholder="Stad"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <hr />
        <b-col cols="12">
          <h3>Gegevens van de hoofdverantwoordelijke</h3>
          <table class="table">
            <tr>
              <td>Voornaam</td>
              <td>{{ account.KeyUserFirstName }}</td>
            </tr>
            <tr>
              <td>Achternaam</td>
              <td>{{ account.KeyUserLastName || 'Onbekend' }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ account.KeyUserEmail || 'Onbekend' }}</td>
            </tr>
            <tr>
              <td>Mobiel</td>
              <td>{{ account.KeyUserMobilePhone || 'Onbekend' }}</td>
            </tr>
            <tr>
              <td>Telefoon</td>
              <td>{{ account.KeyUserTel || 'Onbekend' }}</td>
            </tr>
          </table>
        </b-col>

        <b-col cols="12">
          <b-button
            v-if="!isMainContact"
            class="mb-2"
            type="submit"
            variant="primary"
            ><font-awesome-icon icon="save" /> Gegevens opslaan</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
const { updateCustomer } = require('@/services/AuthorizationService')
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BForm
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BForm
  },
  props: {
    user: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        customerEmail: '',
        customerName: '',
        customerDescription: '',
        street: '',
        housenumber: '',
        housenumberaddition: '',
        customerOrganisationNumber: '',
        zipCode: '',
        city: '',
        website: '',
        CUST_SocialMedia: ''
      }
    }
  },
  computed: {
    account: function() {
      return this.$store.getters.account
    },

    isMainContact: function() {
      if (this.$store.getters.account.RoleName !== 'portal') {
        return false
      } else {
        return true
      }
    }
  },
  created: function() {
    this.form.customerEmail = this.account.CustomerEmail
    this.form.CustomerStreet = this.account.CustomerStreet
    this.form.customerDescription = this.account.CustomerCUST_Notes
    this.form.zipCode = this.account.CustomerZipCode
    this.form.housenumber = this.account.CustomerHouseNumber
    this.form.housenumberaddition = this.account.CustomerHouseNumberAddition
    this.form.customerOrganisationNumber = this.account.ECCUST_EnterpriseNumber
    this.form.city = this.account.CustomerCity
    this.form.customerName = this.account.CompanyName
    this.form.website = this.account.website
    this.form.CUST_SocialMedia = this.user.CUST_SocialMedia
  },
  methods: {
    adjustCustomer: async function() {
      const result = await updateCustomer({
        CustomerEmail: this.form.customerEmail,
        CustomerStreet: this.form.CustomerStreet,
        ZipCode: this.form.zipCode,
        HouseNumber: this.form.housenumber,
        HouseNumberAddition: this.form.housenumberaddition,
        Website: this.form.website,
        City: this.form.city,
        CompanyName: this.form.customerName,
        CustomerDescription: this.account.CustomerCUST_Notes,
        ECCUST_EnterpriseNumber: this.form.customerOrganisationNumber,
        CUST_SocialMedia: this.form.CUST_SocialMedia
      })
      if (result) {
        this.notify({ message: 'Gegevens succesvol bijgewerkt!' })
      }
    },
    notify({ message }) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        appendToast: false,
        title: 'success!',
        toaster: 'b-toaster-bottom-right'
      })
    }
  }
}
</script>

const testdata = [
  {
    id: 'e0',
    startDate: '2021-03-02',
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`,
    dwayne: true,
    items: [
      {
        itemname: 'Grasmaaier',
        itemID: 234234,
        description: 'Grote grasmaaier met een 2 taks 9pk motor'
      },
      {
        itemname: 'Kruiwagen',
        itemID: 234256,
        description: 'Grote kruiwagen met een inhoud van 40 liter'
      }
    ]
  },
  {
    id: 'e1',
    startDate: thisMonth(15, 18, 30),
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`
  },
  {
    id: 'e2',
    startDate: thisMonth(15),
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`
  },
  {
    id: 'e3',
    startDate: thisMonth(7, 9, 25),

    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`
  },
  {
    id: 'e4',
    startDate: thisMonth(20),
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`,
    classes: 'birthday',
    url: 'https://en.wikipedia.org/wiki/Birthday'
  },
  {
    id: 'e5',
    startDate: thisMonth(5),

    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`,
    classes: 'bg-secondary'
  },
  {
    id: 'foo',
    startDate: thisMonth(29),
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`
  },
  {
    id: 'e6',
    startDate: thisMonth(29),
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`,
    classes: 'bg-secondary'
  },
  {
    id: 'e7',
    startDate: thisMonth(29),
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`
  },
  {
    id: 'e8',
    startDate: thisMonth(29),
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`,
    classes: 'bg-secondary'
  },
  {
    id: 'e9',
    startDate: thisMonth(29),
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`
  },
  {
    id: 'e10',
    startDate: thisMonth(29),
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`,
    classes: 'bg-secondary'
  },
  {
    id: 'e11',
    startDate: thisMonth(29),
    title: `Keuring PL-${Math.floor(Math.random() * 100000) + 1}`
  }
]

function thisMonth(d, h, m) {
  const t = new Date()
  return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
}

export { testdata }

<template>
  <div>
    <b-button size="sm" @click="showModal" class="mb-3"
      ><font-awesome-icon icon="edit"
    /></b-button>
    <b-form @submit="onSubmit">
      <b-modal
        :id="`modal-${serialid}`"
        :title="`Serienummer ${serialid} aanpassen`"
      >
        <b-form-group
          id="serialidlabel"
          label="Serienummer"
          label-for="serialid"
        >
          <b-form-input
            id="serialid"
            v-model="form.serialid"
            type="text"
            disabled
            placeholder="Voer het serienummer in"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="itemidlabel" label="Artikel" label-for="itemid">
          <b-form-select
            id="itemid"
            v-model="form.itemid"
            :options="items"
            required
            disabled
          ></b-form-select>
        </b-form-group>

        <b-form-group id="commentlabel" label="Opmerking" label-for="comment">
          <b-form-textarea
            id="comment"
            v-model="form.comment"
            placeholder="Geef eventueel een opmerking op"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-card class="mt-3" header="Form Data Result">
          <pre class="m-0">{{ form }}</pre>
        </b-card>
        <template #modal-footer>
          <div class="w-100">
            <b-button type="submit" variant="primary"
              >Serienummer bijwerken</b-button
            >
            <b-button
              variant="secondary"
              class="float-right"
              @click="hideModal()"
            >
              Sluiten
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BFormTextarea
} from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BFormTextarea
  },
  props: {
    serialid: {
      required: true,
      type: String,
      default: function() {
        return Math.floor(Math.random() * 100000) + 1
      }
    },
    itemid: {
      required: true,
      type: String,
      default: ''
    },
    comment: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        serialid: '',
        itemid: '',
        comment: ''
      },
      items: [
        { text: 'Selecteer het bijbehorende artikel', value: null },
        { text: 'Doosan Hybrid DX255', value: 'doosan-hdx255' },
        { text: 'Caterpillar DG293', value: 'catpil-dg293' },
        { text: 'Volvo AX839UP', value: 'volvo-ax839up' }
      ],
      show: true
    }
  },
  created: function() {
    this.form = {
      serialid: this.serialid,
      itemid: this.itemid,
      comment: this.comment
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show(`modal-${this.serialid}`)
    },
    hideModal() {
      this.$bvModal.hide(`modal-${this.serialid}`)
    },
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    }
  }
}
</script>

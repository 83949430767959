<template>
  <div>
    <b-button size="sm" @click="showModal" class="mb-3"
      ><font-awesome-icon icon="edit"
    /></b-button>
    <b-form @submit="onSubmit">
      <b-modal
        :id="`modal-${this.itemid}`"
        :title="`Artikel ${itemdescription} aanpassen`"
      >
        <b-form-group id="itemidlabel" label="Artikelnummer" label-for="itemid">
          <b-form-input
            id="itemid"
            v-model="form.itemid"
            type="text"
            placeholder="Voer het artikelnummer in"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="itemdescriptionlabel"
          label="Omschrijving"
          label-for="itemdescription"
        >
          <b-form-input
            id="itemdescription"
            v-model="form.itemdescription"
            placeholder="Geef een omschrijving van het artikel op"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="commentlabel" label="Opmerking" label-for="comment">
          <b-form-textarea
            id="comment"
            v-model="form.comment"
            placeholder="Geef eventueel een opmerking op"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-card class="mt-3" header="Form Data Result">
          <pre class="m-0">{{ form }}</pre>
        </b-card>
        <template #modal-footer>
          <div class="w-100">
            <b-button type="submit" variant="primary"
              >Artikel bijwerken</b-button
            >
            <b-button
              variant="secondary"
              class="float-right"
              @click="hideModal()"
            >
              Sluiten
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BFormTextarea
} from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BFormTextarea
  },
  props: {
    itemid: {
      required: true,
      type: String,
      default: function() {
        return Math.floor(Math.random() * 100000) + 1
      }
    },
    itemdescription: {
      required: true,
      type: String,
      default: ''
    },
    comment: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        itemid: '',
        itemdescription: '',
        comment: ''
      },
      show: true
    }
  },
  created: function() {
    this.form = {
      itemid: this.itemid,
      itemdescription: this.itemdescription,
      comment: this.comment
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show(`modal-${this.itemid}`)
    },
    hideModal() {
      this.$bvModal.hide(`modal-${this.itemid}`)
    },
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    }
  }
}
</script>

<template>
  <div>
    <b-button
      :v-b-modal="serialid"
      class="mb-3"
      size="sm"
      variant="primary"
      @click="showModal"
      ><font-awesome-icon icon="info-circle"
    /></b-button>

    <b-modal
      :id="`modal-${serialid}`"
      size="lg"
      :title="`Serienummer informatie ${serialid}`"
    >
      <b-tabs content-class="mt-3">
        <b-tab title="Artikel" active><item-info /></b-tab>
        <b-tab title="Serienummer"><serial-info /></b-tab>
        <b-tab title="Services"><serial-service-info /></b-tab>
        <b-tab title="Bestanden"
          ><file-download :key="key" :type="type"
        /></b-tab>
      </b-tabs>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="hideModal()"
          >
            Sluiten
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BTabs, BTab } from 'bootstrap-vue'
import FileDownload from './FileDownload'

import ItemInfo from '@/components/item/ItemInfo'
import SerialInfo from '@/components/serial/SerialInfo'
import SerialServiceInfo from '@/components/serial/SerialServiceInfo'

export default {
  components: {
    BModal,
    BButton,
    BTabs,
    BTab,
    ItemInfo,
    SerialInfo,
    SerialServiceInfo,
    FileDownload
  },
  props: {
    serialid: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show(`modal-${this.serialid}`)
    },
    hideModal() {
      this.$bvModal.hide(`modal-${this.serialid}`)
    }
  }
}
</script>

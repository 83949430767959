<template>
  <b-container>
    <bread-crumbs />

    <b-row>
      <b-col>
        <h1>Mijn account</h1>
      </b-col>
    </b-row>

    <p>Op de account pagina vind u uw account gegevens en reserveringen</p>

    <b-alert v-if="alertInfoMessage" show variant="danger">{{
      alertInfoMessage
    }}</b-alert>
    <div class="account-tabs">
      <b-tabs pills class="pl-0 ml-0">
        <hr />
        <b-tab title="Mijn gegevens" active>
          <User :user="user" />
        </b-tab>
        <b-tab title="Bedrijf">
          <Organisation :user="user" />
        </b-tab>
        <b-tab title="Contactpersonen">
          <Contact />
        </b-tab>
        <b-tab title="Reserveringen">
          <Reservations />
        </b-tab>
        <b-tab title="Mijn serienummers">
          <Serials />
        </b-tab>
        <b-tab title="Mijn artikelen">
          <Items />
        </b-tab>
        <b-tab title="Serienummers in huur">
          <rented-serials />
        </b-tab>
        <b-tab title="Artikelen in huur">
          <rented-items />
        </b-tab>
        <b-tab title="Services">
          <services />
        </b-tab>
      </b-tabs>
    </div>
  </b-container>
</template>
<script>
import User from '@/views/Account/User'
import Organisation from '@/views/Account/Organisation'
import Reservations from '@/views/Account/Reservations'
import Items from '@/views/Account/Items'
import RentedItems from '@/views/Account/RentedItems'
import Serials from '@/views/Account/Serials'
import RentedSerials from '@/views/Account/RentedSerials'
import Services from '@/views/Account/Services'
import Contact from '@/views/Account/Contact'
import { BRow, BCol, BContainer, BTab, BTabs } from 'bootstrap-vue'

export default {
  components: {
    User,
    Organisation,
    Reservations,
    Contact,
    BRow,
    BCol,
    BContainer,
    BTab,
    BTabs,
    Items,
    RentedItems,
    Serials,
    RentedSerials,
    Services
  },
  metaInfo: {
    title: `Mijn account`
  },
  computed: {
    user: function() {
      return this.$store.getters.user
    },
    alertInfoMessage: function() {
      if (this.$route.query.message) {
        this.notify({ message: this.$route.query.message })
        return
      }
      if (this.$route.query.infoMessage) return this.$route.query.infoMessage
      return null
    }
  },
  created: function() {
    if (this.$route.params.loggedIn) {
      this.notify({ message: 'Succesvol ingelogd' })
    }
    if (this.$route.params.message) {
      this.notify({ message: this.$route.parma.message })
    }
  },

  methods: {
    notify({ message }) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        appendToast: false,
        title: 'success!',
        toaster: 'b-toaster-bottom-right'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.account-tabs {
  min-height: 610px;
}
</style>

<template>
  <div>
    <h3>Mijn serienummers</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend
      metus enim, eu tristique dui sollicitudin sit amet. Sed interdum, felis
      quis tincidunt condimentum, nisl elit tempor justo, dictum pulvinar velit
      metus eu eros.
    </p>

    <serials-modal-create />
    <b-table
      v-if="reservations.length > 0"
      :items="reservations"
      :fields="fields"
      striped
      responsive
    >
      <template v-slot:cell(Status)="data">
        <span>{{
          reservationStatus(data.item.Status, data.item.WarehouseID)
        }}</span>
      </template>
      <template v-slot:cell(ReservationID)="data">
        <router-link
          :to="{
            name: 'reservation',
            params: { reservationID: data.item.ReservationOrderID }
          }"
          >#{{ data.item.ReservationOrderID }}</router-link
        >
      </template>
      <template v-slot:cell(TotalIncVAT)="data">
        <div class="form-group text-right">
          &euro;
          {{
            (Math.round(data.item.TotalIncVAT * 100) / 100)
              .toFixed(2)
              .replace('.', ',')
          }}
        </div>
      </template>

      <template v-slot:cell(DateTimeBusinessStart)="data">
        {{ data.item.DateTimeBusinessStart | moment('DD/MM/YYYY') }}
      </template>
      <template v-slot:cell(DateTimeExpectedEnd)="data">
        {{ data.item.DateTimeExpectedEnd | moment('DD/MM/YYYY') }}
      </template>
      <template v-slot:cell(ReportID)="data">
        <div class="d-flex">
          <serials-modal-edit class="mr-2" />

          <serials-modal-info :serialid="data.item.ReservationOrderID" />
        </div>
      </template>
      <template v-slot:cell(ContactFirstName)="data">
        {{ data.item.ContactFirstName }} {{ data.item.ContactLastName }}
      </template>
    </b-table>
    <p v-else>
      U heeft nog geen reserveringen om te bekijken. Wanneer u een nieuwe
      reservering aanmaakt zal deze hier worden getoond.
    </p>
  </div>
</template>

<script>
import {
  getReservations,
  getReservationReport
} from '@/services/ReservationService'

import SerialsModalCreate from './SerialsModalCreate'
import SerialsModalEdit from './SerialsModalEdit'
import SerialsModalInfo from './SerialsModalInfo'
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    SerialsModalCreate,
    SerialsModalEdit,
    SerialsModalInfo
  },
  data() {
    return {
      reservations: [],
      fields: [
        {
          key: 'ReservationID',
          label: 'Serienummer'
        },
        {
          key: 'Status',
          label: 'Status'
        },
        {
          key: 'TotalIncVAT',
          label: 'Totaal',
          class: 'text-right no-break '
        },
        {
          key: 'WarehouseID',
          label: 'Locatie'
        },
        {
          key: 'DateTimeBusinessStart',
          label: 'Afhaaldatum'
        },
        {
          key: 'DateTimeExpectedEnd',
          label: 'Terugbrengdatum'
        },
        {
          key: 'ReportID',
          label: 'Acties'
        },

        {
          key: 'ReservationOrderID',
          label: '',
          class: 'd-none'
        },
        {
          key: 'AttachmentToken',
          label: '',
          class: 'd-none'
        }
      ]
    }
  },
  created: async function() {
    this.reservations = await getReservations()
    this.$store.commit('setReservations', { reservations: this.reservations })
  },
  methods: {
    reservationStatus: function(status) {
      if (status === 'Active' || status === 'Open' || status === 'Actief')
        return 'Aangevraagd'

      if (status === 'Order') return 'Reservering bevestigd'

      if (status === 'Pick' || status === 'Picked') return 'In behandeling'

      if (status === 'Pending') return 'Uitgeleend'

      if (status === 'Closed') return 'Ingeleverd'

      if (status === 'Cancelled' || status === 'Expired') return 'Geannuleerd'

      if (status === 'Rejected') return 'Afgewezen'

      return 'Aangevraagd'
    },
    getReport: async function({
      reportID,
      reservationOrderID,
      reportFileName,
      attachmentToken
    }) {
      await getReservationReport({
        reservationOrderID: reservationOrderID,
        reportID: reportID,
        attachmentToken,
        fileName: `${reservationOrderID}-${reportFileName}`
      })
    }
  }
}
</script>

<style scss></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Mijn reserveringen")]),_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend metus enim, eu tristique dui sollicitudin sit amet. Sed interdum, felis quis tincidunt condimentum, nisl elit tempor justo, dictum pulvinar velit metus eu eros. ")]),(_vm.reservations.length > 0)?_c('b-table',{attrs:{"items":_vm.reservations,"striped":"","fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"cell(Status)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.reservationStatus(data.item.Status, data.item.WarehouseID)))])]}},{key:"cell(ReservationID)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'reservation',
          params: { reservationID: data.item.ReservationOrderID }
        }}},[_vm._v("#"+_vm._s(data.item.ReservationOrderID))])]}},{key:"cell(TotalIncVAT)",fn:function(data){return [_c('div',{staticClass:"form-group text-right"},[_vm._v(" € "+_vm._s((Math.round(data.item.TotalIncVAT * 100) / 100) .toFixed(2) .replace('.', ','))+" ")])]}},{key:"cell(DateTimeBusinessStart)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("moment")(data.item.DateTimeBusinessStart,'DD/MM/YYYY'))+" ")]}},{key:"cell(DateTimeExpectedEnd)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("moment")(data.item.DateTimeExpectedEnd,'DD/MM/YYYY'))+" ")]}},{key:"cell(ReportID)",fn:function(data){return [(data.item.ReportID)?_c('span',{staticClass:"text-primary",staticStyle:{"cursor":"pointer"},attrs:{"variant":"link"},on:{"click":function($event){return _vm.getReport({
            reportID: data.item.ReportID,
            reservationOrderID: data.item.ReservationOrderID,
            reportFileName: data.item.ReportFileName,
            attachmentToken: data.item.AttachmentToken
          })}}},[_vm._v(_vm._s(data.item.ReportFileName))]):_vm._e()]}},{key:"cell(ContactFirstName)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.ContactFirstName)+" "+_vm._s(data.item.ContactLastName)+" ")]}}],null,false,3421550968)}):_c('p',[_vm._v(" U heeft nog geen reserveringen om te bekijken. Wanneer u een nieuwe reservering aanmaakt zal deze hier worden getoond. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
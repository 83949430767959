<template>
  <div>
    <b-button v-b-modal.newserial class="mb-3"
      >Nieuw serienummer aanmaken</b-button
    >
    <b-form @submit="onSubmit">
      <b-modal id="newserial" title="Nieuw serienummer toevoegen">
        <b-form-group
          id="serialidlabel"
          label="Serienummer"
          label-for="serialid"
        >
          <b-form-input
            id="serialid"
            v-model="form.serialid"
            type="text"
            placeholder="Voer het serienummer in"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="itemidlabel" label="Artikel" label-for="itemid">
          <b-form-select
            id="itemid"
            v-model="form.itemid"
            :options="items"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group id="commentlabel" label="Opmerking" label-for="comment">
          <b-form-textarea
            id="comment"
            v-model="form.comment"
            placeholder="Geef eventueel een opmerking op"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-card class="mt-3" header="Form Data Result">
          <pre class="m-0">{{ form }}</pre>
        </b-card>
        <template #modal-footer>
          <div class="w-100">
            <b-button type="submit" variant="primary"
              >Serienummer aanmaken</b-button
            >
            <b-button
              variant="secondary"
              class="float-right"
              @click="hideModal()"
            >
              Sluiten
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BFormTextarea
} from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BFormTextarea
  },
  data() {
    return {
      form: {
        serialid: '',
        itemid: '',
        comment: ''
      },
      items: [
        { text: 'Selecteer het bijbehorende artikel', value: null },
        { text: 'Doosan Hybrid DX255', value: 'doosan-hdx255' },
        { text: 'Caterpillar DG293', value: 'catpil-dg293' },
        { text: 'Volvo AX839UP', value: 'volvo-ax839up' }
      ],
      show: true
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide(`newserial`)
    },
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    }
  }
}
</script>
